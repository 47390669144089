<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="loadData()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                <el-table-column prop="title" label="标题" width="250" align="center"></el-table-column>
                <el-table-column prop="uri" label="文件链接" align="center"></el-table-column>
                <el-table-column prop="desc" label="描述" align="center"></el-table-column>
                <el-table-column label="位置" prop="position_name" align="center" width="150"></el-table-column>
                <!-- <el-table-column prop="r" label="编号" align="center"></el-table-column> -->
                <el-table-column label="图片" align="center" width="80" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;;" :class="`img1column${scope.$index}`" class="" fit="cover" v-if="scope.row.image" :src="scope.row.image" :preview-src-list="[scope.row.image]">
                        </el-image>
                        <i v-else class="el-icon-picture-outline avatar"></i>
                    </template>
                </el-table-column>
                <el-table-column label="logo图标" align="center" width="80" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px;;" :class="`img2column${scope.$index}`" fit="cover" v-if="scope.row.logo" :src="scope.row.logo" :preview-src-list="[scope.row.logo]"></el-image>
                        <i v-else class="el-icon-picture-outline avatar"></i>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <el-input v-model="form.desc" placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item label="位置" prop="position">
                    <el-select v-model="form.position" placeholder="请选择位置" class="handle-select mr10">
                        <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                    </el-select>
                </el-form-item>
                <!-- 图片上传 -->
                <el-form-item label="图片" prop="image">
                    <el-input v-model="form.image" placeholder="请输入图片链接"></el-input>
                    <el-button @click.prevent="choiceImg('image')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img1" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.image" :src="form.image" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸100*100像素</span>
                </el-form-item>
                <!-- logo上传 -->
                <el-form-item label="logo图标" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入logo链接"></el-input>
                    <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img2" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" name="file" :headers="header">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸100*100像素</span>
                </el-form-item>
                <!-- 文件上传 -->
                <el-form-item label="文件链接" prop="uri">
                    <el-input v-model="form.uri" placeholder="请输入文件链接"></el-input>
                    <el-upload class="avatar-uploader up" action="/i/admin/system/upload/static" :show-file-list="false" :on-success="handleHtmlSuccess" :before-upload="beforeHtmlUpload" name="file" :headers="header">
                        <i v-if="form.uri" class="el-icon-folder-add avatar"></i>
                        <i v-else class="el-icon-upload2 avatar"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="状态" prop="state" placeholder="请选择状态">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 删除 -->
        <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
            <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="removeVisible = false">取 消</el-button>
                <el-button type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            header: {},
            menuList: ['店铺', '页面配置'],
            queryParams: {
                searchName: '',
                page: 1,
                pagesize: 10
            },
            hearder: {},
            positionList: [],
            platformList: [],
            //验证规则
            rules: {
                title: [{
                    required: true,
                    message: '标题不能为空',
                    trigger: 'blur'
                }],
                desc: [{
                    required: true,
                    message: '描述不能为空',
                    trigger: 'blur'
                }],
                position: [{
                    required: true,
                    message: '位置不能为空',
                    trigger: 'blur'
                }],
                image: [
                    {
                        required: true,
                        message: '图片不能为空',
                        trigger: 'blur'
                    },
                    { validator: deal.checkImage, trigger: ['blur', 'change'] }
                ],
                logo: [
                    {
                        required: true,
                        message: '图片不能为空',
                        trigger: 'blur'
                    },
                    { validator: deal.checkImage, trigger: ['blur', 'change'] }
                ],
                uri: [
                    { validator: deal.checkFile, trigger: ['blur', 'change'], required: true, }
                ],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            loading: false,
            pageInfo: {},
            addVisible: false,
            removeVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {
            },

        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';

        this.loadData();
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //获取配置列表
        loadData() {
            this.loading = true;
            request.get('/common/page/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        handleLogoSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeLogoUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        handleHtmlSuccess(res, file) {
            if (res.code == 1) {
                console.log(88888, res.data)
                this.$set(this.form, 'uri', res.data.uri);
            }
        },
        //文件上传
        beforeHtmlUpload(file) {
            let fileName = file.name
            let pos = fileName.lastIndexOf('.')
            let lastName = fileName.substring(pos, fileName.length)
            console.log(66666, lastName)
            if (lastName.toLowerCase() !== '.html') {
                this.$message.error('不支持此文件格式!');
            }
            // Loading.service({ text: '文件上传中' });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增
        add() {
            deal.getconf('webview', this)
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            deal.getconf('webview', this)
            this.mode = 'edit';
            this.title = '编辑';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            // let time = [item.ctime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form);
            this.addVisible = true;
        },
        remove(index, row) {
            const item = this.pageInfo.list[index];
            this.form = item;
            this.removeVisible = true;
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        //编辑新增
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/common/page/add' : '/common/page/edit';
                        // this.form.ctime = deal.timestamp(this.form.time[0]);
                        // this.form.etime = deal.timestamp(this.form.time[1]);
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/common/express/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }

    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
